<template>
  <div
    id="modal_container-info"
    class="modal_container ad_modal"
    :class="{ active: showBox }"
    @click="closeModal"
  >
    <div class="modal" @click.stop>
      <div class="close_btn h2" @click="closeModal"><span>×</span></div>
      <div class="modal_content" v-html="showContent">
        <!-- <a href="https://www.twhomecare.org/register/annual/info?event_id=62d8bdc608663">
          <img src="@/statics/img/index/homecare_ad.jpeg" alt="" >
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'lightbox',
  data() {
    return {
      showBox: false,
    };
  },
  computed: {
    ...mapState(['adLightBox']),
    ...mapGetters(['homeAdInfo']),
    showContent() {
      return this.homeAdInfo?.content || '';
    },
    showAd() {
      if (!this.showContent || this.showContent === '') {
        return false;
      }
      return +this.homeAdInfo?.status === 1;
    },
  },
  watch: {
    showAd(val) {
      if (val) this.toggleLightbox(val);
    },
  },
  methods: {
    ...mapMutations(['toggleADLightbox']),
    lockBody(status) {
      const el = document.getElementsByTagName('body')[0];
      if (status) {
        el.classList.add('lock');
      } else {
        el.classList.remove('lock');
      }
    },
    toggleLightbox(status) {
      this.lockBody(status);
      this.showBox = status;
    },
    openModal() {
      this.toggleLightbox(true);
    },
    closeModal() {
      this.toggleLightbox(false);
    },
  },
  // created() {
  //   if (this.adLightBox) {
  //     this.lockBody(true);
  //   }
  // },
};
</script>

<style lang="scss">
.ad_modal.modal_container {
  .modal {
    padding-bottom: 40px;
  }
}

</style>
