<template>
  <div id="about">
    <PageHeader
      :pageHeader="pageHeader"
      :kvTitle1Color="kvTitle1Color"
      :kvTitle2Color="kvTitle2Color"
    />
    <router-view/>
  </div>
</template>

<script>
import '@/assets/scss/about.scss';

import PageHeader from '@/components/PageHeader.vue';
import { AboutKv } from '@/lib/const';

export default {
  title: '認識學會',
  name: 'AboutHome',
  data() {
    return {
      ...AboutKv,
      kvTitle1Color: 'txt-white',
      kvTitle2Color: 'txt-white',
    };
  },
  components: {
    PageHeader,
  },
};
</script>
