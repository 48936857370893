// import store from '@/store';
import { request, toFormData } from '@/boot/axios';
import { timeFormat, dayFormat } from '@/lib/public';

// eslint-disable-next-line no-unused-vars
function tokenTest(response) {
  if (response.data.message === 'FAILURE_CREDENTIALS_MISSING') {
    return '使用此功能前請先登入';
  }
  if (response.data.message === 'FAILURE_IDENTITY_NOT_FOUND') {
    return '狀態已過期，請重新登入';
  }
  return response.data.result;
}

function fileDownload(res, name, extension) {
  const targetUrl = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = targetUrl;
  link.setAttribute('download', `${name}.${extension}`);
  document.body.appendChild(link);
  link.click();
}
/**
 * 系統參數
 */
export function getSysVars() {
  return request({
    url: '/sys-vars',
    method: 'get',
  }).then((res) => res.data.result.data);
}
/**
 * 訂單付款
 */
export function orderPay(data) {
  return request({
    url: '/orders/pay',
    method: 'post',
    data: toFormData(data, true),
  }).then((res) => res);
}
/**
 * 入會申請
 */
export function registerAsMember(data) {
  return request({
    url: '/users/apply',
    method: 'post',
    data: toFormData(data),
    binary: true,
  }).then((res) => res);
}
/**
 * 入會申請-補資料
 */
export function registerUpload(data) {
  return request({
    url: '/users/upload',
    method: 'post',
    data: toFormData(data),
    binary: true,
  }).then((res) => res);
}
/**
 * 查詢訂單未繳費記錄
 */
export function orderList(params) {
  return request({
    url: '/orders',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 非會員註冊
 */
export function registerAsNonmember(data) {
  return request({
    url: '/users/apply/',
    method: 'post',
    data: toFormData(data),
  }).then((res) => res);
}
/**
 * 登入
 */
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    token: null,
    data: toFormData(data),
  }).then((res) => res);
}
/**
 * 註冊資料查詢
 */
export function userInfo() {
  return request({
    url: '/users/view',
    method: 'get',
  }).then((res) => res.data.result.user);
}
/**
 * 申請審核結果查詢
 */
export function idVerify(data) {
  return request({
    url: '/users/verify',
    method: 'post',
    data: toFormData(data),
  }).then((res) => res.data.result.data || null);
}
/**
 * 申請審核繳費查詢
 */
export function applyOrders(data) {
  return request({
    url: '/users/verify',
    method: 'post',
    data: toFormData(data),
  }).then((res) => res.data.result);
}
/**
 * 線上課程：報名、付款
 */
export function courseApply(data) {
  return request({
    url: '/courses/apply',
    method: 'post',
    data: toFormData(data, true),
  }).then((res) => res.data);
}
/**
 * 付款結果確認
 */
export function paymentInfo(params) {
  return request({
    url: '/payments/info',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 各類文章-index
 */
export function articleIndex(params) {
  return request({
    url: '/articles',
    method: 'get',
    params,
  }).then((res) => res.data.result);
}
/**
 * 各類文章-view
 */
export function articleView(params) {
  return request({
    url: '/articles/view',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 線上課程-index
 */
export function courses(params) {
  return request({
    url: '/courses',
    method: 'get',
    params,
  }).then((res) => {
    if (res !== undefined) {
      const data = res.data.result.data.map((item) => ({
        ...item,
        length: timeFormat(item.length),
        expiry_time: dayFormat(item.expiry_time),
      }));
      return { ...res.data.result, data };
    }
    return {};
  });
}
/**
 * 線上課程-view
 */
export function coursesView(params) {
  return request({
    url: '/courses/view',
    method: 'get',
    params,
  }).then((res) => {
    // function fractionName(id) {
    //   let target = '';
    //   store.getters.activityType.forEach((item) => {
    //     if (item.id * 1 === id * 1 && item.name) {
    //       target = item.name;
    //     }
    //   });
    //   return target;
    // }
    let { data } = res.data.result;
    data.length = timeFormat(data.length);
    data.expiry_days = dayFormat(data.expiry_time);
    // data.fraction_name = fractionName(data.fraction_type_id);
    const courseVideos = data.course_videos.map((item) => ({
      ...item,
      length: timeFormat(item.length),
    }));
    data = { ...data, course_videos: courseVideos };
    return { ...res.data.result, data };
  });
}
/**
 * 線上課程查詢
 */
export function courseCar(data) {
  return request({
    url: '/courses/car',
    method: 'post',
    data: toFormData(data),
  }).then((res) => {
    if (res) {
      return res.data.result.data;
    }
    return [];
  });
}
/**
 * 我的課程
 */
export function myCourses(params) {
  return request({
    url: '/course-users',
    method: 'get',
    params,
  }).then((res) => {
    const resCheck = res.data.result;
    if (resCheck.length !== 0 || Object.keys(resCheck).length !== 0) {
      const data = res.data.result.data.map((item) => ({
        ...item,
        create_date: item.created.split(' ')[0],
        create_time: item.created.split(' ')[1],
      }));
      return { ...res.data.result, data };
    }
    return {};
  });
}
/**
 * 單元觀看進度查詢
 */
export function videoProgress(params) {
  return request({
    url: '/course-user-videos/view',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 單元觀看進度更新
 */
export function videoStatus(data) {
  return request({
    url: '/course-user-videos',
    method: 'post',
    data: toFormData(data, true),
  }).then((res) => res.data.result);
}
/**
 * 線上課程單元作答
 */
export function courseAnswer(data) {
  // const form = new FormData();
  // form.append('video_uuid', data.video_uuid);
  // data.answer.forEach((item, k) => {
  //   form.append(`answer[${k}][uuid]`, item.uuid);
  //   form.append(`answer[${k}][answer]`, item.answer);
  // });
  return request({
    url: '/course-user-answers',
    method: 'post',
    data: toFormData(data, true),
  }).then((res) => res.data.result.data);
}
/**
 * 活動紀錄
 */
export function classList(params) {
  return request({
    url: '/users/classLists',
    method: 'get',
    params,
  }).then((res) => {
    // tokenTest();
    if (res.data.message === 'FAILURE_CREDENTIALS_MISSING') {
      return '使用此功能前請先登入';
    }
    if (res.data.message === 'FAILURE_IDENTITY_NOT_FOUND') {
      return '狀態已過期，請重新登入';
    }
    return res.data.result;
  });
}
/**
 * 會員資料更新
 */
export function userUpdate(data) {
  return request({
    url: '/users/update',
    method: 'post',
    data: toFormData(data, true),
  }).then((res) => res);
}
/**
 * 專科申請
 */
export function specialistApply(data) {
  return request({
    url: '/specialists/apply',
    method: 'post',
    data: toFormData(data),
    binary: true,
  }).then((res) => res);
}
/**
 * 展延申請
 */
export function specialistExtend(data) {
  return request({
    url: '/specialists/extend',
    method: 'post',
    data: toFormData(data),
    binary: true,
  }).then((res) => res);
}
/**
 * 專科/展延申請－查詢
 */
export function specialistInfo(params) {
  return request({
    url: '/specialists/info',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 專科/展延申請－補件
 */
export function specialistUpload(data) {
  return request({
    url: '/specialists/upload',
    method: 'post',
    data: toFormData(data),
    binary: true,
  }).then((res) => res);
}
/**
 * 積分總計查詢
 */
export function fractionSearch() {
  return request({
    url: '/users/total-fractions',
    method: 'get',
  }).then((res) => res.data.result);
}
/**
 * 我的積分列表
 */
export function myFractions(params) {
  return request({
    url: '/users/fractions',
    method: 'get',
    params,
  }).then((res) => res.data.result);
}
/**
 * 收據列印
 */
export function downloadReceipt(params) {
  return request({
    url: '/order-details/print',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 上課證明列印
 */
export function downloadAttendence(params) {
  return request({
    url: '/order-details/hours-print',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 行事曆
 */
export function calendar(params) {
  return request({
    url: '/events/calendar',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 學術活動 index
 */
export function eventsIndex(params) {
  return request({
    url: '/events',
    method: 'get',
    params,
  }).then((res) => {
    const resCheck = res.data.result;
    if (resCheck.length > 0 || Object.keys(resCheck).length > 0) {
      const data = res.data.result.data.map((item) => ({
        ...item,
        // startYear: item.start_date.split('T')[0].split('-')[0],
        // startMonth: item.start_date.split('T')[0].split('-')[1],
        // startDay: item.start_date.split('T')[0].split('-')[2],
      }));
      return { ...res.data.result, data };
    }
    return {};
  });
}
/**
 * 學術活動 view
 */
export function eventsView(params) {
  return request({
    url: '/events/view',
    method: 'get',
    params,
  }).then((res) => res.data);
}
/**
 * 活動報名
 */
export function eventsMemberApply(data) {
  return request({
    url: '/events/memberApply',
    method: 'post',
    data: toFormData(data),
  }).then((res) => res);
}
/**
 * 積分申請
 */
export function eventsApply(data) {
  return request({
    url: '/events/apply',
    method: 'post',
    data: toFormData(data),
    binary: true,
  }).then((res) => res);
}
/**
 * 積分申請-成果上傳
 */
export function eventsUpload(data) {
  return request({
    url: '/events/apply-result-upload',
    method: 'post',
    data: toFormData(data),
    binary: true,
  }).then((res) => res);
}
/**
 * 積分申請-回覆函下載
 */
// eslint-disable-next-line no-unused-vars
export function eventsReply(data, extension) {
  return request({
    url: '/events/reply-apply',
    method: 'post',
    data: toFormData(data),
    download: true,
  }).then((res) => {
    let resError = '';
    if (res.status === 500) {
      resError = '自設密碼錯誤';
    } else {
      fileDownload(res, '積分回覆函', extension);
    }
    return resError;
  });
}
/**
 * 積分申請-查詢列表
 */
export function eventsApplyIndex(params) {
  return request({
    url: '/events/apply-index',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 積分申請-檢視
 */
export function eventsApplyView(params) {
  return request({
    url: '/events/apply-view',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 積分申請-繳費收據
 */
export function eventsReceipt(data) {
  return request({
    url: '/order-details/event-apply-print',
    method: 'post',
    data: toFormData(data),
  }).then((res) => res);
}
/**
 * 年會-index
 */
export function annualIndex(params) {
  return request({
    url: '/events',
    method: 'get',
    params: { ...params, host_type: 4 },
  }).then((res) => res);
}
/**
 * 年會-view
 */
export function annualView(params) {
  return request({
    url: '/events/view',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 單筆訂單未繳付紀錄檢視
 */
export function orderView(params) {
  return request({
    url: '/orders/view',
    method: 'get',
    params,
  }).then((res) => res);
}
/**
 * 地區碼查詢
 */
export function zipcode() {
  return request({
    url: '/sys-vars/zipcode',
    method: 'get',
  }).then((res) => res.data.result.data);
}
