import Vue from 'vue';
import IdleVue from 'idle-vue';
import vuescroll from 'vue-scroll';
import VueSlideToggle from 'vue-slide-toggle';
import VueYoutube from 'vue-youtube';
import VueResize from 'vue-resize';
import VueCookies from 'vue-cookies';
import VCalendar from 'v-calendar';
import { MonthPickerInput } from 'vue-month-picker';
import axios from 'axios';
import VueAxios from 'vue-axios';
import titleMixin from '@/mixins/titleMixin';
/**
 * Font Awesome
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHammer, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import router from './router';
import store from './store';

import 'vue-resize/dist/vue-resize.css';

import '@/assets/scss/main.scss';
import '@/assets/scss/public.scss';

// import '@/assets/scss/home.scss';

import '@/lib/public';

// Hamburger Menu
import '@/statics/hamburgers.css';

/**
 * Idle-Vue
 */
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 10800000,
});

/**
 * Font Awesome
 */
library.add(faHammer, faWrench);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.mixin(titleMixin);
Vue.use(vuescroll);
Vue.use(VueSlideToggle);
Vue.use(VueYoutube);
Vue.use(VueResize);
Vue.use(VueCookies);
Vue.use(VCalendar);
Vue.use(MonthPickerInput);
Vue.use(VueAxios, axios);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

new Vue({
  VueSlideToggle,
  VueYoutube,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

const token = VueCookies.get('token');
if (token) {
  axios.defaults.headers.common.Authorization = token;
}
