<template>
  <div id="app">
    <Header :componentKey="componentKey" @forceRefresh="forceRefresh" />
    <router-view />
    <Footer />
    <ModalInfo />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ModalInfo from '@/components/ModalInfo.vue';
import { mapActions } from 'vuex';
import { getSysVars } from '@/lib/http';
// eslint-disable-next-line no-unused-vars
import { setAxiosHeaders } from '@/boot/axios';

export default {
  name: 'App',
  data() {
    return {
      componentKey: 0,
    };
  },
  /**
   * Idle-Vue event
   */
  /** 閒置中 */
  onIdle() {
    this.logout()
      .then(() => {
        this.$router.push({ path: '/login' });
        setAxiosHeaders(null);
      });
  },
  /** 使用中 */
  onActive() {
  },
  created() {
    /**
     * 判斷Browser狀態是Reload or Close
     */
    // if (window.performance) {
    //   /** Type: Reload */
    //   if (performance.navigation.type === 1) {

    //   /** Type: Close */
    //   } else {
    //     this.logout().then(() => {
    //       setAxiosHeaders(null);
    //     });
    //   }
    // }
  },
  mounted() {
    this.init();
    getSysVars()
      .then((res) => {
        this.setSysVars(res);
      });
  },
  methods: {
    ...mapActions([
      'init',
      'setSysVars',
      'logout',
    ]),
    forceRefresh() {
      this.componentKey += 1;
    },
  },
  components: {
    Header,
    Footer,
    ModalInfo,
  },
};
</script>
