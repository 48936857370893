<template>
  <div
    id="modal_container-info"
    class="modal_container"
    :class="{active: openModalInfo}"
    @click="closeModal"
  >
    <div class="modal txt-center" @click.stop>
      <div class="close_btn h2" @click="closeModal"><span>×</span></div>
      <div class="modal_content mg-b-50">
        <p class="txt-bold" v-html="modalInfoContent"></p>
      </div>
      <div class="modal_confirm">
        <button
          v-if="btnLink"
          type="button"
          class="btn p"
          @click="goTo"
        >
          確認
        </button>
        <button
          v-else
          type="button"
          class="btn p"
          @click="closeModal">
          確認
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';

export default {
  name: 'ModalInfo',
  computed: {
    ...mapState([
      'urlRedirect',
      'btnLink',
    ]),
    ...mapGetters([
      'openModalInfo',
      'modalInfoContent',
    ]),
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
    ]),
    ...mapActions([
      'initModal',
    ]),
    closeModal() {
      this.toggleModalInfo(false);
      if (this.urlRedirect) {
        this.$router.push({ path: this.urlRedirect });
      }
      this.initModal();
    },
    goTo() {
      this.$router.push({ path: this.btnLink });
      this.initModal();
    },
  },
  watch: {
    openModalInfo() {
      const el = document.getElementsByTagName('body')[0];
      if (this.openModalInfo) {
        el.classList.add('lock');
      } else {
        el.classList.remove('lock');
      }
    },
    $route() {
      this.closeModal();
    },
  },
};
</script>
