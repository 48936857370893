import Vue from 'vue';
// import store from '@/store/index';
import VueCookies from 'vue-cookies';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import AboutHome from '@/views/about/AboutHome.vue';
import AboutIndex from '@/views/about/AboutIndex.vue';
import AboutAssociation from '@/views/about/AboutAssociation.vue';
import AboutChairman from '@/views/about/AboutChairman.vue';
import AboutMemberList from '@/views/about/AboutMemberList.vue';
import AboutAccording from '@/views/about/AboutAccording.vue';
import AboutContact from '@/views/about/AboutContact.vue';

Vue.use(VueCookies);
Vue.use(VueRouter);

/** 關閉重複導向及攔截導向的錯誤 */
const { isNavigationFailure, NavigationFailureType } = VueRouter;

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (
      err.name !== 'NavigationDuplicated'
      && !isNavigationFailure(err, NavigationFailureType.redirected)
    ) throw err;
  });
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/receipt',
    component: () => import('@/views/pdf/Receipt.vue'),
  },
  {
    path: '/attendance',
    component: () => import('@/views/pdf/Attendance.vue'),
  },
  {
    path: '/about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutHome,
    children: [
      {
        path: '',
        component: AboutIndex,
        alias: 'index',
      },
      {
        path: 'association',
        component: AboutAssociation,
      },
      {
        path: 'chairman',
        component: AboutChairman,
      },
      {
        path: 'memberList',
        component: AboutMemberList,
      },
      {
        path: 'according',
        component: AboutAccording,
      },
      {
        path: 'contact',
        component: AboutContact,
      },
    ],
  },
  {
    path: '/news',
    component: () => import('../views/news/NewsHome.vue'),
    children: [
      {
        path: ':type',
        component: () => import('@/views/news/NewsIndex.vue'),
      },
      {
        path: ':type/article',
        component: () => import('@/views/news/NewsArticle.vue'),
      },
    ],
  },
  {
    path: '/register',
    component: () => import('../views/register/RegisterHome.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/register/RegisterCalender.vue'),
        // component: () => import('@/views/Construction.vue'),
        alias: 'calender',
      },
      {
        path: 'event',
        component: () => import('@/views/register/RegisterEventIndex.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'event/info',
        component: () => import('@/views/register/RegisterEventInfo.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'event/member',
        component: () => import('@/views/register/RegisterEventMember.vue'),
        // component: () => import('@/views/Construction.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'event/nonmember',
        component: () => import('@/views/register/RegisterEventNonmember.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'event/payment',
        component: () => import('@/views/register/RegisterEventPayment.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'event/confirm',
        component: () => import('@/views/register/RegisterEventConfirm.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'event/success',
        component: () => import('@/views/register/RegisterEventSuccess.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'online',
        component: () => import('@/views/register/RegisterOnlineIndex.vue'),
        alias: 'online/index',
      },
      {
        path: 'online/grocery',
        component: () => import('@/views/register/RegisterOnlineGrocery.vue'),
      },
      {
        path: 'online/grocery/payment',
        component: () => import('@/views/register/RegisterOnlinePayment.vue'),
      },
      {
        path: 'online/grocery/confirm',
        component: () => import('@/views/register/RegisterOnlineConfirm.vue'),
      },
      {
        path: 'online/grocery/success',
        component: () => import('@/views/register/RegisterOnlineSuccess.vue'),
        beforeEnter: async (to, from, next) => {
          const store = await import('@/store/index');
          const { hasToken } = store.default.getters;

          if (!hasToken) {
            store.default.commit('toggleModalIdentity', true);
            next({ path: from.path, query: { redirect: to.fullPath } });
          } else {
            next();
          }
        },
      },
      {
        path: 'online/mycourse',
        component: () => import('@/views/register/RegisterOnlineMycourse.vue'),
        // meta: { requireAuth: true },
        beforeEnter: async (to, from, next) => {
          const store = await import('@/store/index');
          const { hasToken } = store.default.getters;

          if (!hasToken) {
            store.default.commit('toggleModalIdentity', true);
            next({ path: from.path, query: { redirect: to.fullPath } });
          } else {
            next();
          }
        },
      },
      {
        path: 'online/mycourse/info',
        component: () => import('@/views/register/RegisterOnlineMycourseInfo.vue'),
      },
      {
        path: 'online/mycourse/video',
        component: () => import('@/views/register/RegisterOnlineMycourseVideoCopy.vue'),
      },
      {
        path: 'online/mycourse/exam',
        component: () => import('@/views/register/RegisterOnlineMycourseExam.vue'),
      },
      {
        path: 'online/mycourse/result',
        component: () => import('@/views/register/RegisterOnlineMycourseResult.vue'),
      },
      {
        path: 'annual',
        component: () => import('@/views/register/RegisterAnnualIndex.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'annual/info',
        component: () => import('@/views/register/RegisterAnnualInfo.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'annual/member',
        component: () => import('@/views/register/RegisterAnnualMember.vue'),
        // component: () => import('@/views/Construction.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'annual/nonmember',
        component: () => import('@/views/register/RegisterAnnualNonmember.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'annual/payment',
        component: () => import('@/views/register/RegisterAnnualPayment.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'annual/confirm',
        component: () => import('@/views/register/RegisterAnnualConfirm.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'annual/success',
        component: () => import('@/views/register/RegisterAnnualSuccess.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'record',
        component: () => import('@/views/register/RegisterRecord.vue'),
        // meta: { requireAuth: true },
        beforeEnter: async (to, from, next) => {
          const store = await import('@/store/index');
          const { hasToken } = store.default.getters;

          if (!hasToken) {
            store.default.commit('toggleModalIdentity', true);
            next({ path: from.path, query: { redirect: to.fullPath } });
          } else {
            next();
          }
        },
      },
      {
        path: 'record/payment',
        component: () => import('@/views/order/Payment.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'record/confirm',
        component: () => import('@/views/order/Confirm.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'record/success',
        component: () => import('@/views/order/Success.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'point',
        component: () => import('@/views/register/RegisterPointApply.vue'),
        // component: () => import('@/views/Construction.vue'),
        alias: 'point/apply',
      },
      {
        path: 'point/search',
        component: () => import('@/views/register/RegisterPointSearch.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'point/search/payment',
        component: () => import('@/views/register/RegisterPointSearchPayment.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'point/search/confirm',
        component: () => import('@/views/register/RegisterPointSearchConfirm.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'point/search/success',
        component: () => import('@/views/register/RegisterPointSearchSuccess.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'point/search/upload',
        component: () => import('@/views/register/RegisterPointSearchUpload.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
    ],
  },
  {
    path: '/hotlink',
    component: () => import('../views/hotlink/HotlinkHome.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/hotlink/HotlinkRelative.vue'),
        alias: 'relative',
      },
      {
        path: 'medicine',
        component: () => import('@/views/hotlink/HotlinkMedicine.vue'),
      },
    ],
  },
  {
    path: '/account',
    component: () => import('../views/account/AccountHome.vue'),
    meta: { requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/account/AccountIndex.vue'),
        meta: { requireAuth: true },
        alias: 'index',
      },
      {
        path: 'apply',
        component: () => import('@/views/account/AccountApplyProfession.vue'),
        // component: () => import('@/views/Construction.vue'),
        meta: { requireAuth: true },
        alias: 'apply/profession',
      },
      {
        path: 'apply/extension',
        component: () => import('@/views/account/AccountApplyExtension.vue'),
        // component: () => import('@/views/Construction.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'search',
        component: () => import('@/views/account/AccountSearchFee.vue'),
        meta: { requireAuth: true },
        alias: 'search/fee',
      },
      {
        path: 'search/payment',
        component: () => import('@/views/order/Payment.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'search/confirm',
        component: () => import('@/views/order/Confirm.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'search/success',
        component: () => import('@/views/order/Success.vue'),
        // component: () => import('@/views/Construction.vue'),
      },
      {
        path: 'search/points',
        component: () => import('@/views/account/AccountSearchPoints.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'search/profession',
        component: () => import('@/views/account/AccountSearchProfession.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'search/extension',
        component: () => import('@/views/account/AccountSearchExtension.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'manual',
        component: () => import('@/views/account/AccountManualIndex.vue'),
        // component: () => import('@/views/Construction.vue'),
        meta: { requireAuth: true },
        alias: 'manual/index',
      },
      {
        path: 'manual/pdf',
        component: () => import('@/views/account/AccountManualPdf.vue'),
        // component: () => import('@/views/Construction.vue'),
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/LoginIndex.vue'),
  },
  {
    path: '/login/forget',
    name: 'LoginForget',
    component: () => import('../views/login/LoginForget.vue'),
  },
  {
    path: '/application/index',
    name: 'ApplicationIndex',
    component: () => import('../views/application/ApplicationIndex.vue'),
    // component: () => import('@/views/Construction.vue'),
  },
  {
    path: '/application/verify',
    name: 'ApplicationVerify',
    component: () => import('../views/application/ApplicationVerify.vue'),
    // component: () => import('@/views/Construction.vue'),
  },
  {
    path: '/application/payment',
    name: 'ApplicationPayment',
    component: () => import('../views/application/ApplicationPayment.vue'),
    // component: () => import('@/views/Construction.vue'),
  },
  {
    path: '/application/confirm',
    name: 'ApplicationConfirm',
    component: () => import('../views/application/ApplicationConfirm.vue'),
    // component: () => import('@/views/Construction.vue'),
  },
  {
    path: '/application/success',
    name: 'ApplicationSuccess',
    component: () => import('../views/application/ApplicationSuccess.vue'),
    // component: () => import('@/views/Construction.vue'),
  },
  /** 建置中頁面 */
  // {
  //   path: '/construction',
  //   component: () => import('@/views/Construction.vue'),
  // },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const store = await import('@/store/index');
  const { isAuthenticated } = store.default.getters;

  const el = document.getElementsByTagName('body')[0];
  el.classList.remove('lock');

  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!isAuthenticated) {
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
