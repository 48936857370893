<template>
  <div id="association">
    <div class="container w1100 page_header_space">
      <!-- 標題 -->
      <h3 class="txt-bold">學會章程</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
      <!-- 內文 -->
      <div class="info_box w900">
        <!-- <ol
          v-for="associationItem in associationList"
          :key="associationItem.id"
          class="info_list"
        >
          <li class="info_item">
            <div class="top">
              <h4 class="txt-bold">{{ associationItem.title }}</h4>
              <div class="arrow">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <div class="bottom">
              <div
                v-for="innerItem in associationItem.inner"
                :key="innerItem.id"
                class="text_list"
              >
                <p class="txt-bold">{{ innerItem.title }}</p>
                <div v-html="innerItem.content" >{{ innerItem.content }}</div>
              </div>
            </div>
          </li>
        </ol> -->
        <ol class="info_list">
          <li class="info_item">
            <div class="top" @click="toggleBottomList1" :class="{active: topActive1}">
              <h4 class="txt-bold">
                第一章 總則
              </h4>
              <div class="arrow">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open1" :duration="500">
              <div class="bottom_spacing">
                <div class="text_list">
                  <p class="txt-bold">第一條</p>
                  <div>
                    <p>本會名稱為台灣居家醫療醫學會（以下簡稱本會）。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二條</p>
                  <div>
                    <p>本會為依法設立、非以營利為目的之社會團體，以促進居家醫療醫學會的研究與發展，
                      推行居家醫療專科制度，加強與國際性居家醫療科醫學會團體之交流與聯繫，並結合社會資源，
                      推動老人福利及關懷相關議題人才培力，並提高國內基層醫療水準，配合政府推動長期照顧服務為宗旨。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第三條</p>
                  <div>
                    <p>本會以全國行政區域為組織區域。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第四條</p>
                  <div>
                    <p>
                      本會會址設於主管機關所在地區，並得報經主管機關核准設分支機構。
                      前項分支機構組織簡則由理事會擬訂，報請主管機關核准後行之。
                      會址及分支機構之地址於設置及變更時應函報主管機關核備。
                    </p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第五條</p>
                  <div>
                    <p>本會之任務如下：</p>
                    <p>一、規劃居家醫療銜接辦理長期照顧服務。</p>
                    <p>二、結合社會資源，推動老人福利和長期照顧計畫，及相關議題和人才培訓。</p>
                    <p>三、舉辦有關居家醫療科之學術演講、討論會及居家醫療之研討暨進修。</p>
                    <p>四、建立居家醫療醫師對專業能力的訓練。</p>
                    <p>五、做好居家醫療制度上的願景。</p>
                    <p>六、建立健保署端、居家病人端、居家醫療醫師端，能得到最佳的三贏。並為居家醫療醫師會員，幫助會員溝通、處理醫病關係、減少醫療糾紛。</p>
                    <p>七、配合政府老人福利政策及長期照顧計劃相關法令規章，承辦政府機關所委託之相關業務。</p>
                    <p>八、加強與國際性居家醫療科醫學團體之交流與聯繫。</p>
                    <p>九、有效監督健保署對居家醫療的給付。</p>
                    <p>十、其他有關居家醫療科醫學促進事項。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第六條</p>
                  <div>
                    <p>
                      本會之主管機關為內政部。本會之目的事業應受各該事業主管機關之指導、監督。
                    </p>
                  </div>
                </div>
              </div>
            </VueSlideToggle>
          </li>
        </ol>
        <ol class="info_list">
          <li class="info_item">
            <div class="top" @click="toggleBottomList2" :class="{active: topActive2}">
              <h4 class="txt-bold">
                第二章 會員
              </h4>
              <div class="arrow">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open2" :duration="500">
              <div class="bottom_spacing">
                <div class="text_list">
                <p class="txt-bold">第七條</p>
                <div>
                  <p>本會會員分類如下：</p>
                  <p>一、個人會員：凡贊同本會宗旨、年滿二十歲、具有中華民國醫師證書，
                    且領有醫師證書滿二年，並由會員二人介紹，填具入會申請書，經理事會審查通過，並繳納會費後，為個人會員。</p>
                  <p>二、贊助會員：凡贊同本會宗旨之個人，填具入會申請書，經理事會審查通過為贊助會員。</p>
                  <p>三、榮譽會員：凡對居家醫療有特殊貢獻之個人，並贊同本會宗旨，由理事會審查通過為榮譽會員。</p>
                </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第八條</p>
                  <div>
                    <p>
                      會員（會員代表）有表決權、選舉權、被選舉權與罷免權。每一會員（會員代表）為一權。 贊助會員、榮譽會員，無前項權利。
                    </p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第九條</p>
                  <div>
                    <p>會員有遵守本會章程、決議及繳納會費之義務。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十條</p>
                  <div>
                    <p>會員（會員代表）有違反法令，章程或不遵守會員大會決議時，得經理事會決議，
                      予以警告或停權處分，其危害團體情節重大者，得經會員大會決議予以除名。
                      會員未繳納會費者，不得享有會員權利，連續二年未繳納會費者，視為自動退會。
                      會員經出會、退會或停權處分，如欲申請復會或復權時，除有正當理由經理事會審核通過者外，
                      應繳清前所積欠之會費。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十一條</p>
                  <div>
                    <p>會員有下列情事之一者，為出會：一、喪失會員資格者。二、經會員大會決議除名者。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十二條</p>
                  <div>
                    <p>會員得以書面敘明理由向本會聲明退會。</p>
                  </div>
                </div>
              </div>
            </VueSlideToggle>
          </li>
        </ol>
        <ol class="info_list">
          <li class="info_item">
            <div class="top" @click="toggleBottomList3" :class="{active: topActive3}">
              <h4 class="txt-bold">
                第三章 組織及職權
              </h4>
              <div class="arrow">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open3" :duration="500">
              <div class="bottom_spacing">
                <div class="text_list">
                <p class="txt-bold">第十三條</p>
                <div>
                  <p>本會以會員大會為最高權力機構。會員（會員代表）人數超過三百人以上時得分區比例選出會員代表，
                    再召開會員代表大會，行使會員大會職權。會員代表任期4年，其名額及選舉辦法由理事會擬訂，報請主管機關核備後行之。</p>
                </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十四條</p>
                  <div>
                    <p>會員（會員代表）大會之職權如下：</p>
                    <p>一、訂定與變更章程。 </p>
                    <p>二、選舉及罷免理事、監事。 </p>
                    <p>三、議決入會費、常年會費、事業費及會員捐款之數額及方式。 </p>
                    <p>四、議決年度工作計畫、報告及預算、決算。</p>
                    <p>五、議決會員（會員代表）之除名處分。</p>
                    <p>六、議決財產之處分。</p>
                    <p>七、議決本會之解散。</p>
                    <p>八、議決與會員權利義務有關之其他重大事項。</p>
                    <p>前項第八款重大事項之範圍由理事會定之。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十五條</p>
                  <div>
                    <p>本會置理事九人、監事三人，由會員或會員代表選舉之，分別成立理事會、監事會。
                      選舉前項理事、監事時，依計票情形得同時選出候補理事兩人，候補監事一人，遇理事、監事出缺時，
                      分別依序遞補之。理事會得提出下屆理事、監事候選人參考名單。理事、監事得採用通訊選舉。
                      通訊選舉辦法由理事會通過，報請主管機關核備後行之。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十六條</p>
                  <div>
                    <p>理事會之職權如下： </p>
                    <p>一、審定會員（會員代表）之資格。</p>
                    <p>二、選舉及罷免常務理事、副理事長、理事長。</p>
                    <p>三、議決常務理事、副理事長及理事長之辭職。</p>
                    <p>四、聘免工作人員。</p>
                    <p>五、擬訂年度工作計畫、報告及預算、決算。</p>
                    <p>六、其他應執行事項。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十七條</p>
                  <div>
                    <p>理事會置常務理事3人，由理事互選之，並由理事就常務理事中選舉1人為理事長、2人為副理事長。
                      理事長對內綜理督導會務，對外代表本會，並擔任會員大會、理事會主席。理事長因事不能執行職務時，
                      應指定副理事長1人代理之，未指定或不能指定時，由常務理事互推1人代理之。
                      理事長、副理事長、常務理事出缺時，應於1個月內補選之。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十八條</p>
                  <div>
                    <p>監事會之職權如下： </p>
                    <p>一、監察理事會工作之執行。</p>
                    <p>二、審核年度決算。</p>
                    <p>三、選舉及罷免常務監事。</p>
                    <p>四、議決監事及常務監事之辭職。</p>
                    <p>五、其他應監察事項。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第十九條</p>
                  <div>
                    <p>監事會置常務監事1人，由監事互選之，監察日常會務，並擔任監事會主席。
                      常務監事因事不能執行職務時，應指定監事1人代理之，未指定或不能指定時，
                      由監事互推1人代理之。監事會主席（常務監事）出缺時應於一個月內補選之。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十條</p>
                  <div>
                    <p>理事、監事均為無給職，任期4年，連選得連任。理事長之連任，以1次為限。
                      理事、監事之任期自召開本屆第1次理事會之日起計算。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十一條</p>
                  <div>
                    <p>理事、監事有下列情事之一者，應即解任：</p>
                    <p>一、喪失會員（會員代表）資格者。</p>
                    <p>二、因故辭職經理事會或監事會決議通過者。</p>
                    <p>三、被罷免或撤免者。</p>
                    <p>四、受停權處分期間逾任期二分之一者。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十二條</p>
                  <div>
                    <p>本會置秘書長一人，承理事長之命處理本會事務，其他工作人員若干人，
                      由理事長提名經理事會通過聘免之，並報主管機關備查。前項工作人員不得由理事監事擔任。
                      工作人員權責及分層負責事項由理事會另定之。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十三條</p>
                  <div>
                    <p>本會得設各種委員會、小組或其他內部作業組織，其組織簡則經理事會通過後施行，變更時亦同。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十四條</p>
                  <div>
                    <p>本會得由理事會聘請名譽理事長一人，名譽理事、顧問若干人，其聘期與理事、監事之任期同。</p>
                  </div>
                </div>
              </div>
            </VueSlideToggle>
          </li>
        </ol>
        <ol class="info_list">
          <li class="info_item">
            <div class="top" @click="toggleBottomList4" :class="{active: topActive4}">
              <h4 class="txt-bold">
                第四章 會議
              </h4>
              <div class="arrow">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open4" :duration="700">
              <div class="bottom_spacing">
                <div class="text_list">
                  <p class="txt-bold">第二十五條</p>
                  <div>
                    <p>
                      會員（會員代表）大會分定期會議與臨時會議二種，由理事長召集，
                      召集時除緊急事故之臨時會議外應於十五日前以書面通知之。定期會議每年召開一次，
                      臨時會議於理事會認為必要，或經會員（會員代表）五分之一以上之請求，或監事會函請召集時召開之。
                      本會辦理法人登記後，臨時會議經會員（會員代表）十分之一以上之請求召開之。
                    </p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十六條</p>
                  <div>
                    <p>會員（會員代表）不能親自出席會員大會時，得以書面委託其他會員（會員代表）代理，每一會員（會員代表）以代理一人為限。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十七條</p>
                  <div>
                    <p>會員（會員代表）大會之決議，以會員（會員代表）過半數之出席，出席人數較多數之同意行之。但下列事項之決議以出席人數三分之二以上同意行之。</p>
                    <p>一、章程之訂定與變更。</p>
                    <p>二、會員（會員代表）之除名。</p>
                    <p>三、理事、監事之罷免。</p>
                    <p>四、財產之處分。</p>
                    <p>五、本會之解散。</p>
                    <p>六、其他與會員權利義務有關之重大事項。</p>
                    <p>本會辦理法人登記後，章程之變更以出席人數四分之三以上之同意或全體會員三分之二以上書面之同意行之。
                      本會之解散，得隨時以全體會員三分之二以上之可決解散之。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十八條</p>
                  <div>
                    <p>理事會每6個月召開一次，監事會每6個月召開一次，必要時得召開聯席會議或臨時會議。
                      前項會議召集時除臨時會議外，應於七日前以書面通知，會議之決議，各以理事、監事過半數之出席，
                      出席人數較多數之同意行之。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第二十九條</p>
                  <div>
                    <p>理事應出席理事會議，監事應出席監事會議，理事會、監事會不得委託出席：理事、監事連續二次無故缺席理事會、監事會者，視同辭職</p>
                  </div>
                </div>
              </div>
            </VueSlideToggle>
          </li>
        </ol>
        <ol class="info_list">
          <li class="info_item">
            <div class="top" @click="toggleBottomList5" :class="{active: topActive5}">
              <h4 class="txt-bold">
                第五章 經費及會計
              </h4>
              <div class="arrow">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open5" :duration="500">
              <div class="bottom_spacing">
                <div class="text_list">
                  <p class="txt-bold">第三十條</p>
                  <div>
                    <p>本會經費來源如下：</p>
                    <p>一、入會費：個人會員：新台幣2000元，於會員入會時繳納。</p>
                    <p>二、常年會費：個人會員：新台幣3000元。</p>
                    <p>三、事業費。</p>
                    <p>四、會員捐款。</p>
                    <p>五、委託收益。</p>
                    <p>六、基金及其孳息。</p>
                    <p>七、其他收入。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第三十一條</p>
                  <div>
                    <p>本會會計年度以曆年為準，自每年一月一日起至十二月三十一日止。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第三十二條</p>
                  <div>
                    <p>本會每年於會計年度開始前二個月由理事會編造年度工作計畫、收支預算表、員工待遇表，
                      提會員大會通過（會員大會因故未能如期召開者，先提理監事聯席會議通過），
                      於會計年度開始前報主管機關核備。並於會計年度終了後二個月內由理事會編造年度工作報告、
                      收支決算表、現金出納表、資產負債表、財產目錄及基金收支表，送監事會審核後，
                      造具審核意見書送還理事會，提會員大會通過，於三月底前報主管機關核備（會員大會未能如期召開，
                      先經理事監事聯席會議通過後，再報主管機關）。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第三十三條</p>
                  <div>
                    <p>本會於解散後，剩餘財產歸屬所在地之地方自治團體或主管機關指定之機關團體所有。</p>
                  </div>
                </div>
              </div>
            </VueSlideToggle>
          </li>
        </ol>
        <ol class="info_list">
          <li class="info_item">
            <div class="top" @click="toggleBottomList6" :class="{active: topActive6}">
              <h4 class="txt-bold">
                第六章 附則
              </h4>
              <div class="arrow">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open6" :duration="500">
              <div class="bottom_spacing">
                <div class="text_list">
                  <p class="txt-bold">第三十四條</p>
                  <div>
                    <p>本章程未規定事項，悉依有關法令規定辦理。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第三十五條</p>
                  <div>
                    <p>本章程經會員（會員代表）大會通過，報經主管機關核備後施行，變更時亦同。</p>
                  </div>
                </div>
                <div class="text_list">
                  <p class="txt-bold">第三十六條</p>
                  <div>
                    <p>本章程經本會108年05月26日第一屆第一次會員大會通過。</p>
                  </div>
                </div>
              </div>
            </VueSlideToggle>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';

export default {
  name: 'AboutAssociation',
  data() {
    return {
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      topActive1: false,
      topActive2: false,
      topActive3: false,
      topActive4: false,
      topActive5: false,
      topActive6: false,
      // associationList: [
      //   {
      //     id: 1,
      //     title: '第一章 總則',
      //     inner: [
      //       {
      //         id: 1,
      //         title: '第一條',
      //         content: '本會定名為台灣婦產科醫學會(原中華民國婦產科醫學會)以下簡稱本會。',
      //       },
      //       {
      //         id: 2,
      //         title: '第二條',
      //         content: `<p>本會宗旨如下：</p>
      //             <p>一、提高婦產科醫療水準。</p>
      //             <p>二、促進對婦產科醫學之研究。</p>
      //             <p>三、維護會員權益。</p>
      //             <p>四、與國際性婦產科醫學團體之交流。</p>`,
      //       },
      //       {
      //         id: 3,
      //         title: '第三條',
      //         content: '本會總會設於主管機關所在地區。本會得以行政區域設立分會，分會之設立須經會員代表大會之同意，其組織另訂之。',
      //       },
      //       {
      //         id: 4,
      //         title: '第四條',
      //         content: '本會主管機關為內政部，目的事業主管機關為衛生署。',
      //       },
      //       {
      //         id: 5,
      //         title: '第五條',
      //         content: `一、增進婦嬰健康及疾病之防治。<br>
      //                   二、發行婦產科學術雜誌，舉辦學術演講及推廣專科醫師繼續教育。<br>
      //                   三、參與國際婦產科學術活動與國內外專科團體之交流及合作。<br>
      //                   四、促進與健康保險機構之溝通和協調，維護會員權益及促進醫病關係和諧。`,
      //       },
      //     ],
      //   },
      //   {
      //     id: 2,
      //     title: '第二章 會員',
      //     inner: [
      //       {
      //         id: 1,
      //         title: '第六條',
      //         content: `本會會員申請資格如下：<br>
      //                   一、國內外公認醫學院校醫科畢業。<br>
      //                   二、獲得醫師資格。<br>
      //                   三、獲得衛生署認可具有專科醫師資格。申請時必需提出證明文件及會員二人介紹，經理事會之通過，並繳納會費。`,
      //       },
      //       {
      //         id: 2,
      //         title: '第七條',
      //         content: `本會準會員申請資格如下：<br>
      //                   一、本會會員依據專科醫師甄審辦法再審不合格者。<br>
      //                   二、在婦產科專科醫師訓練醫院婦產科服務者，入會資格審查辦法另訂之。`,
      //       },
      //       {
      //         id: 3,
      //         title: '第八條',
      //         content: '連續二年未繳清年費者或停會六年未復會者，視為自動退會。',
      //       },
      //       {
      //         id: 4,
      //         title: '第九條',
      //         content: '會員凡有違法令、章程、不遵守會員代表大會決議或有損本會信譽者，得經理事會決議，
      //                   予以警告或停權處分，其危害團體情節重大者，得經會員代表大會決議予以除名。',
      //       },
      //       {
      //         id: 5,
      //         title: '第十條',
      //         content: '會員喪失會員資格或經會員代表大會決議除名者，即為出會。',
      //       },
      //       {
      //         id: 6,
      //         title: '第十一條',
      //         content: '會員得以書面敘明理由向本會聲明退會。',
      //       },
      //       {
      //         id: 7,
      //         title: '第十二條',
      //         content: '會員有發言權、表決權、選舉權、被選舉權與罷免權，並享受本會應享之公共利益，每一會員為一權。',
      //       },
      //       {
      //         id: 8,
      //         title: '第十三條',
      //         content: '會員有遵守本會章程、決議、繳納會費及擔任所委派職務之義務。',
      //       },
      //       {
      //         id: 9,
      //         title: '第十四條',
      //         content: '準會員有發言權並享受本會應享之公共利益；但無表決權、選舉權及被選舉權。',
      //       },
      //       {
      //         id: 10,
      //         title: '第十五條',
      //         content: '準會員有遵守本會章程、決議、繳納會費及擔任所委派職務之義務。',
      //       },
      //     ],
      //   },
      //   {
      //     id: 3,
      //     title: '第三章 會員代表',
      //     inner: [
      //       {
      //         id: 1,
      //         title: '第十六條',
      //         content: '本會由會員選舉會員代表，組織會員代表大會其任期為三年，連選得連任。會員代表選舉辦法由理事會訂定，經會員代表大會通過，報請主管機關核備後施行。',
      //       },
      //       {
      //         id: 2,
      //         title: '第十七條',
      //         content: '會員代表凡有違反法令、章程、不遵守會員代表大會決議或有損本會信譽者，得經理事會決議，
      //                   予以警告或停權處分，其危害團體情節重大者，得經會員代表大會決議予以除名。',
      //       },
      //       {
      //         id: 3,
      //         title: '第十八條',
      //         content: '會員代表喪失會員代表資格或經會員代表大會決議除名者，即為出會。',
      //       },
      //       {
      //         id: 4,
      //         title: '第十九條',
      //         content: '會員代表得以書面敘明理由向本會聲明退會。',
      //       },
      //       {
      //         id: 5,
      //         title: '第二十條',
      //         content: '會員代表有發言權、表決權、選舉權、被選舉權與罷免權，並享受本會應享之公共利益，每一會員代表為一權。',
      //       },
      //       {
      //         id: 6,
      //         title: '第廿一條',
      //         content: '會員代表有遵守本會章程、決議、繳納會費及擔任所委派職務之義務。',
      //       },
      //     ],
      //   },
      // ],
    };
  },
  methods: {
    toggleBottomList1() {
      this.topActive1 = !this.topActive1;
      this.open1 = !this.open1;
    },
    toggleBottomList2() {
      this.topActive2 = !this.topActive2;
      this.open2 = !this.open2;
    },
    toggleBottomList3() {
      this.topActive3 = !this.topActive3;
      this.open3 = !this.open3;
    },
    toggleBottomList4() {
      this.topActive4 = !this.topActive4;
      this.open4 = !this.open4;
    },
    toggleBottomList5() {
      this.topActive5 = !this.topActive5;
      this.open5 = !this.open5;
    },
    toggleBottomList6() {
      this.topActive6 = !this.topActive6;
      this.open6 = !this.open6;
    },
  },
  components: {
    VueSlideToggle,
  },
};
</script>
