<template>

  <!-- kv -->
  <section
    id="kv_content"
    :style="{ backgroundImage: `url( ${pageHeader.kvImg} )` }">
    <div class="kv_title">
      <h1 :class="kvTitle1Color">{{ pageHeader.kvTitle1 }}</h1>
      <h2 :class="kvTitle2Color">{{ pageHeader.kvTitle2 }}</h2>
    </div>

    <!-- page header -->
    <div class="page_header layout_container">
      <div ref="page_nav" class="page_nav" v-scroll="overflowHandler">

        <!-- overflow arrows -->
        <div class="arrow_wrapper_l" :class="{ active: leftArrowShow }" @mousedown="scrollToLeft">
          <img class="mb_arrow mb_arrow_l" src="@/statics/img/index/icon_arrow_r.svg" alt="">
        </div>
        <div class="arrow_wrapper_r" :class="{ active: rightArrowShow }" @mousedown="scrollToRight">
          <img class="mb_arrow mb_arrow_r" src="@/statics/img/index/icon_arrow_r.svg" alt="">
        </div>

        <!-- page header list -->
        <!-- <li
          v-for="(item, index) in pageHeader.listArray"
          :key="item.id"
          :class="{ active: pageListActiveByTabName(index, item.text) }"
          @click="pageListActive(item.text)"
        > -->
        <div ref="page_nav_box" class="page_nav_box">
          <div
            v-for="item in pageHeader.listArray"
            :key="item.id"
            class="li"
            :class="{
              active: item.id === 1 && $route.path === `/${pageHeader.name}`,
              newsTypeActive: $route.params.type === item.text
            }"
            @click="pageListActive(item.text, item.text)"
          >
            <!-- <router-link
              :to="item.url"
              :class="{
                isFixedCourse: $route.query.course_id === '5fe9501830a05' && item.text === '線上課程'
              }"
            > -->
            <!-- <router-link
              :to="item.url"
              :class="{
                forceDisable: item.text === '安寧課程'
              }"
            > -->
            <router-link
              :to="item.url"
            >
              <h4 class="txt-bold">{{ item.text }}</h4>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'PageHeader',
  props: {
    pageHeader: Object,
    kvTitle1Color: String,
    kvTitle2Color: String,
    newsOnly: Boolean,
  },
  data() {
    return {
      el: this.$refs.page_nav,
      resetTab: 0,
      leftArrowShow: false,
      rightArrowShow: false,
    };
  },
  mounted() {
    /**
     * Drag to Scroll Effect
     */
    const el = this.$refs.page_nav;

    if (typeof el !== 'undefined') {
      let pos = {
        left: 0,
        x: 0,
      };

      const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.x;
        el.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = function () {
        el.removeEventListener('mousemove', mouseMoveHandler);
        el.removeEventListener('mouseup', mouseUpHandler);
      };

      const mouseDownHandler = function (e) {
        pos = {
          left: el.scrollLeft,
          x: e.clientX,
        };

        el.addEventListener('mousemove', mouseMoveHandler);
        el.addEventListener('mouseup', mouseUpHandler);
      };

      el.addEventListener('mousedown', mouseDownHandler);
    }
  },
  updated() {
    // const test = this.$refs.page_nav_box;
    // const containerWidth = test.clientWidth;
    // const contentWidth = test.scrollWidth;
    // const scrollStart = test.scrollLeft;
    // const scrollOffset = contentWidth - containerWidth;
    // console.log('containerWidth', containerWidth);
    // console.log('contentWidth', contentWidth);
    // console.log('scrollStart', scrollStart);
    // console.log('scrollOffset', scrollOffset);
    this.overflowHandler();
  },
  computed: {
    ...mapState([
      'level',
      'currentTabName',
      'newsPageType',
    ]),
    /**
     * 會員專區重組(待刪除功能)：相關查詢其餘頁籤未開發，除了level-1會員外先隱藏
     */
    // computedListArray() {
    //   if (this.pageHeader) {
    //     const copyListArray = [];
    //     this.pageHeader.listArray.forEach((item) => {
    //       if (item.text === '相關查詢' && parseInt(this.level, 10) !== 1) {
    //         return;
    //       }
    //       copyListArray.push(item);
    //     });
    //     return { ...this.pageHeader, listArray: copyListArray };
    //   }
    //   return {};
    // },
  },
  methods: {
    ...mapMutations([
      'updateNewsType',
      'updateTabName',
    ]),
    /**
     * Show Arrows while Overflowing
     */
    overflowHandler() {
      const el = this.$refs.page_nav;

      if (typeof el !== 'undefined') {
        const containerWidth = el.clientWidth;
        const contentWidth = el.scrollWidth;
        const scrollStart = el.scrollLeft;
        const scrollOffset = contentWidth - containerWidth;
        // console.log('containerWidth', containerWidth);
        // console.log('contentWidth', contentWidth);
        // console.log('scrollStart', scrollStart);
        // console.log('scrollOffset', scrollOffset);

        if (contentWidth > containerWidth) {
          if (scrollStart === 0) {
            this.leftArrowShow = false;
            this.rightArrowShow = true;
          } else if (scrollStart <= scrollOffset + 1 && scrollStart >= scrollOffset - 1) {
            this.leftArrowShow = true;
            this.rightArrowShow = false;
          } else if (scrollStart > 0 && scrollStart < scrollOffset) {
            this.rightArrowShow = true;
            this.leftArrowShow = true;
          }
        }
      }
    },
    scrollToLeft() {
      const el = this.$refs.page_nav;
      el.scrollLeft -= 100;
    },
    scrollToRight() {
      const el = this.$refs.page_nav;
      el.scrollLeft += 100;
    },
    pageListActiveByTabName(index, listName) {
      if (this.currentTabName) {
        return this.currentTabName === listName;
      }
      return this.resetTab === index;
    },
    pageListActive(listName, newsType) {
      this.updateTabName(listName);
      if (this.newsOnly) {
        this.updateNewsType(newsType);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.newsTypeActive {
  color: #57bdbd;
}
</style>
