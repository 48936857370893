/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
// eslint-disable-next-line no-unused-vars
// import store from '@/store';
import { API_URL } from '@/lib/config';

let AUTH_TOKEN = null;

Vue.prototype.$axios = axios;

export function toFormData(data, qstr = false) {
  if (qstr) return qs.stringify(data);

  const form = new FormData();
  // Object.keys(data).forEach((key) => {
  //   if (typeof data[key] === 'object' && data[key] !== null) {
  //     Object.keys(data[key]).forEach((childKey) => {
  //       form.append(`${key}[${childKey}]`, data[key][childKey]);
  //     });
  //   } else {
  //     form.append(key, data[key]);
  //   }
  // });
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'object' && data[key] !== null) {
      Object.keys(data[key]).forEach((childKey) => {
        if (typeof data[key][childKey] === 'object' && data[key][childKey] !== null) {
          Object.keys(data[key][childKey]).forEach((grandChildKey) => {
            form.append(`${key}[${childKey}][${grandChildKey}]`, data[key][childKey][grandChildKey]);
          });
        } else {
          form.append(`${key}[${childKey}]`, data[key][childKey]);
        }
      });
    } else {
      form.append(key, data[key]);
    }
  });
  // eslint-disable-next-line no-restricted-syntax
  // for (const pair of form.entries()) {
  //   console.log(`${pair[0]}, ${pair[1]}`);
  // }
  return form;
}

export function setAxiosHeaders(token = null) {
  AUTH_TOKEN = token;
}

export function request(
  {
    url = '',
    method = 'get',
    data = false,
    token = AUTH_TOKEN,
    headers = {},
    params = {},
    binary = false,
    download = false,
  } = {},
) {
  delete axios.defaults.headers.common.Authorization;

  if (token !== null) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  let req = {
    baseURL: API_URL,
    url,
    method,
    headers,
    Accept: 'application/json, text/plain, */*',
    mode: 'no-cors',
    params,
  };

  if (['put', 'post', 'patch'].indexOf(method) !== -1 && data !== false) {
    if (binary) {
      headers = {
        ...headers,
        'Content-Type': 'multipart/form-data',
      };
    } else {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Content-Type': 'application/json',
      };
    }
    req = { ...req, data, headers };
  }

  if (download) {
    req = { ...req, responseType: 'blob' };
  }

  // console.info('req', req);

  return axios(req)
    .then((response) => response)
    .catch((err) => err.response);
}
