<template>
  <div id="according">
    <div class="title w1100 page_header_space">
      <h3 class="txt-bold">『台灣居家醫療專科醫師』甄審評核實施辦法<span class="p"> 111.07.17 修訂</span></h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <section class="content">
      <div class="w1100">
        <p class="indent">一、台灣居家醫療醫學會（以下簡稱本會）為辦理居家醫療專科醫師甄審評核（以下簡稱專科醫師甄審），特訂定本實施辦法。</p>
        <div class="indent">
          <p>二、專科醫師甄審委員會〈以下簡稱本委員會〉，依台灣居家醫療學會組織章程組成。</p>
          <p class="indent_lv2">(一) 本會甄審委員會設甄審委員 3 人，任期與同屆理監事相同，並得連續受聘。</p>
          <p class="indent_lv2">(二) 本會理事長為甄審委員會之主任委員，甄審委員由主任委員提名之，並報請理監事聯席會議同意後聘任之。</p>
          <p class="indent_lv2">(三) 甄審委員之職責為<br>
            1.審核申請甄審者之學經歷。<br>
            2.辦理專科醫師之各項甄審、命題、及評分。<br>
            3.監理專科醫師證書有效期限之展延工作。<br>
            4.其他有關甄審之工作。<br>
          </p>
        </div>
        <div class="indent">
          <p>三、凡申請專科醫師甄審者需具備下列必要條件：</p>
          <p class="indent_lv2">(一) 中華民國西醫師專科醫師且執行居家醫療業務收案滿15例以上(須提供健保署VPN平台資訊佐證)並持續執行業務達六個月以上。</p>
          <p class="indent_lv2">(二) 中華民國西醫師執業滿四年且執行居家醫療業務收案滿15例以上(須提供健保署VPN平台資訊佐證)並持續執行業務達六個月以上。</p>
          <p class="indent_lv2">(三) 符合前述二款資格醫師且居家收案達10例內含安寧療護個案2例。</p>
          <p class="indent_lv2">符合前述三款資格之一且完成下列程序者可向台灣居家醫療醫學會申請專科醫師任認證授予證書。</p>
          <!-- <div class="indent indent_lv2">
            <p>(三) 下列三條件之一：</p>
            <p class="indent_lv3">
              1.凡是施做居家醫療滿二年以上且個案超過 15 人以上者(須提供VPN名單)，且領各種專科醫師執照執業滿4年者，
              皆可於 112 年 05 月 25 日前備妥資料，直接向本醫學會申請台灣居家醫療專科醫師證書。
            </p>
            <p class="indent_lv3 indent_lv3_remark">
              於 109.12.20 及 110.12.26 理監事會議決議共開放100名從事居家醫療醫師，由理事、監事、秘書長推舉，授
              予居家醫療專科醫師資格，適用期限至 112 年 05 月 25 日。
            </p>
            <p class="indent_lv3">
              2.必須有台灣居家醫療醫學會認可之學術含實習63小時課程(本課程內容如附件)。凡於 112 年 05 月 25 日修得
              全部課程，提出申請並繳費後，經本醫學會認證合格者免筆試，即可得居家醫療專科醫師。
            </p>
            <p class="indent_lv3">
              3.民國 112 年 05 月 25 日以後甄審者需筆試。資格審查及筆試均及格者為合格。資格審查或筆試任一項目未合格者，其資格成績得保留四年，逾期需重新申請與完成筆試測驗。
            </p>
          </div> -->
        </div>
        <p class="indent">
          四、參加專科醫師甄審，以通信、網路或親自報名方式為之。
        </p>
        <div class="indent">
          <p>五、參加專科醫師甄審，應繳交下列表件及證明文件：</p>
          <p class="indent_lv2">(一)申請表、個人資料使用同意書。</p>
          <p class="indent_lv2">(二)醫師證書正反面影本。</p>
          <p class="indent_lv2">(三)醫師執業執照影本。</p>
          <p class="indent_lv2">(四)醫師專科醫師證書影本。</p>
          <p class="indent_lv2">(五)最近一年內二吋正面脫帽半身照片兩張。</p>
          <p class="indent_lv2">(六)專科證書審查費用：新台幣壹萬元整。</p>
          <p class="indent_lv2">(七)其他有關證明文件。</p>
        </div>
        <div class="indent">
          <p>六、本會『居家醫療醫學專科醫師』證書有效期限為六年，期滿每次展延期限為六年。申請專科醫師證書有效期限展延，
          應於專科醫師證書之有效期限六年內，參加符合本會專科醫師甄審委員會規定之學術活動或繼續教育課程積分總分達120個學分以上者方可辦理展延，</p>
          <p class="indent_lv2">1、持續執行居家照護個案每年10學分(可以含乙類安寧每年4學分)最多不超過60學分。</p>
          <p class="indent_lv2">2、本學會專業課程60學分。</p>
        </div>
        <div class="indent">
          <p>
          七、申請專科醫師證書有效期限展延，應於公告之期限內，以網路或親自申請方式為之，並繳交下列表件：
        </p>
          <p class="indent_lv2">(一)申請表。</p>
          <p class="indent_lv2">(二)符合第九點所訂展延條件之各項證明文件。</p>
          <p class="indent_lv2">(三)最近一年內二吋正面脫帽半身照片二張。</p>
          <p class="indent_lv2">(四)證書展延費用：新台幣五千元整。</p>
          <p class="indent_lv2">(五)其他有關證明文件。</p>
        </div>
        <p class="indent">八、專科醫師甄審或專科醫師證書有效期限展延，實際收取費用依本會理監事會議決議辦理。</p>
        <p class="indent">
          九、專科醫師甄審或專科醫師證書有效期限展延之有關試卷、論著及資格證明文件等資料， 除留供研究者外，保存二年。
        </p>
        <div class="btn_wrap">
          <router-link to="/account/apply/profession" class="btn">專科申請</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutAccording',
  data() {
    return {

    };
  },
};
</script>
