<template>
  <div id="story">
    <div class="intro w1400 page_header_space">
      <h3 class="txt-bold"><span class="txt-medium">OUR GOALS</span> 成立宗旨</h3>
      <div class="heart-hr">
        <img src="@/statics/img/index/button_heart2.svg" alt="">
      </div>
      <h4>
        促進居家醫療醫學會的研究與發展，推行居家醫療專科制度，並結合社會資源，<br>
        推動老人福利及關懷相關議題人才培力，並提高內基層醫療水準，配合政府推動長期照顧服務。
      </h4>
    </div>

    <section class="main-work">
      <div class="main-bg">
        <img class="pc-bg" src="@/statics/img/about/img_3.webp" alt="">
        <img class="mb-bg" src="@/statics/img/about/img_3_m.webp" alt="">
      </div>

      <div class="main-container">
        <div class="intro w1200">
          <h3 class="txt-bold"><span class="txt-medium">OUR SERVICE</span> 主要工作</h3>
          <div class="heart-hr">
            <img src="@/statics/img/index/button_heart2.svg" alt="">
          </div>

          <div class="list">
            <div class="wrapper">
              <div class="circle_number">1</div>
              <p>落實居家醫療制度上的願景</p><br>
            </div>
            <div class="wrapper">
              <div class="circle_number">2</div>
              <p>給予居家醫療患者最妥善的醫療資源與照護</p><br>
            </div>
            <div class="wrapper">
              <div class="circle_number">3</div>
              <p>有效監督健保署對居家醫療的給付</p><br>
            </div>
            <div class="wrapper">
              <div class="circle_number">4</div>
              <p>建立居家醫療醫師對專業能力的訓練並進行人才培訓，幫助居家醫療醫師會員與病患溝通，處理醫病關係、減少醫療糾紛</p><br>
            </div>
            <div class="wrapper">
              <div class="circle_number">5</div>
              <p>舉辦有關居家醫療科之學術演講、討論會及居家醫療之研討暨進修，加強與國際性居家醫療醫學團體之交流</p>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="organization">
      <div class="intro w1400">
        <h3 class="txt-bold"><span class="txt-medium">ORGANIZATION</span> 組織介紹</h3>
        <div class="heart-hr">
          <img src="@/statics/img/index/button_heart2.svg" alt="">
        </div>
      </div>
      <div class="organization-content w1000">
        <img class="organ-pc" src="@/statics/img/about/organ.webp" alt="">
        <img class="organ-mb" src="@/statics/img/about/organ_m.webp" alt="">
      </div>
      <div class="organization-bg">
        <img class="bg" src="@/statics/img/about/img_4.webp" alt="">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutIndex',
  data() {
    return {

    };
  },
};
</script>
