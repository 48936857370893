<template>
  <!-- 2021/02/02 更改Footer layout -->
  <footer>
    <div class="primary">
      <div class="w1200 container">
        <div class="left">
          <router-link to="/">
            <img class="logo" src="@/statics/img/index/logo.svg" alt="">
          </router-link>
        </div>
        <div class="right">
          <ul class="main_list">
            <li
              v-for="mainList in computedFooter"
              :key="`footer_mainList_${mainList.id}`"
            >
              <router-link :to="mainList.url">
                <p class="txt-bold" @click="tabClicked('全部')">{{ mainList.kvTitle2 }}</p>
              </router-link>
              <ul class="sub_list">
                <li
                  v-for="subList in mainList.listArray"
                  :key="`footer_subList_${subList.id}`"
                >
                  <router-link
                    :to="subList.url"
                  >
                    <small @click.stop="tabClicked(subList.text)">{{ subList.text }}</small>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="secondary">
      <div class="w1400">
        <div class="contact">
          <p class="address small">{{ footerInfo.address }}</p>
          <p class="phone small">電話 {{ footerInfo.tel }} ｜  {{ footerInfo.fax }}</p>
        </div>
        <p class="small">Copyright © Association of Home Care. All Rights Reserved</p>
        <p class="small">最佳瀏覽體驗建議瀏覽器: Edge、Firefox、Chrome、Safari ( 螢幕設定最佳顯示效果為1920*1080 )</p>
      </div>
    </div>
  </footer>
  <!-- 原始Footer -->
  <!-- <footer>
    <div class="primary">
      <div class="w1200 container">
        <div class="left">

          <router-link to="/">
            <img class="logo" src="@/statics/img/index/logo.svg" alt="">
          </router-link>

          <div class="contact">
            <p class="address small">{{ footerInfo.address }}</p>
            <p class="phone small">TEL {{ footerInfo.tel }} ｜ FAX {{ footerInfo.fax }}</p>
          </div>
        </div>
        <div class="right">
          <ul class="main_list">
            <li
              v-for="mainList in computedFooter"
              :key="mainList.kvTitle2"
            >
              <router-link :to="mainList.url">
                <p class="txt-bold" @click="tabClicked('全部')">{{ mainList.kvTitle2 }}</p>
              </router-link>
              <ul class="sub_list">
                <li
                  v-for="subList in mainList.listArray"
                  :key="subList.text"
                >
                  <router-link
                    :to="subList.url"
                  >
                    <small @click.stop="tabClicked(subList.text)">{{ subList.text }}</small>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="secondary">
      <div class="w1400">
        <div class="contact">
          <p class="address small">{{ footerInfo.address }}</p>
          <p class="phone small">TEL {{ footerInfo.tel }} ｜ FAX {{ footerInfo.fax }}</p>
        </div>
        <p class="small">Copyright © Association of Home Care. All Rights Reserved</p>
      </div>
    </div>
  </footer> -->
</template>

<script>
import {
  AboutKv,
  NewsKv,
  HotlinkKv,
  AccountKv,
  RegisterKv,
} from '@/lib/const';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'Footer',
  data() {
    return {
      varList: [],
      footerInfo: {
        address: '宜蘭縣羅東鎮公正路167-3號',
        tel: '0988-206590',
        fax: '03-9562512',
      },
      footerMainListArray: [
        {
          id: 1,
          ...AboutKv.pageHeader,
        },
        {
          id: 2,
          ...NewsKv.pageHeader,
        },
        {
          id: 3,
          ...RegisterKv.pageHeader,
        },
        {
          id: 4,
          ...HotlinkKv.pageHeader,
        },
        // {
        //   id: 5,
        //   ...AccountKv.pageHeader,
        //   // name: '會員專區',
        //   // url: '/account',
        //   // subListArray: [
        //   //   // {
        //   //   //   id: 1,
        //   //   //   name: '會員資料變更',
        //   //   //   url: '/account/index',
        //   //   // },
        //   //   // {
        //   //   //   id: 2,
        //   //   //   name: '相關申請',
        //   //   //   url: '/account/apply',
        //   //   // },
        //   //   {
        //   //     id: 3,
        //   //     name: '相關查詢',
        //   //     url: '/account/search',
        //   //   },
        //   //   // {
        //   //   //   id: 4,
        //   //   //   name: '醫師手冊',
        //   //   //   url: '/account/manual',
        //   //   // },
        //   //   // {
        //   //   //   id: 5,
        //   //   //   name: '活動紀錄',
        //   //   //   url: '/register/record',
        //   //   // },
        //   // ],
        // },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'newsType',
    ]),
    ...mapState([
      'isLogin',
    ]),
    /**
     * 學會訊息相關頁籤重組結構
     */
    computedPageHeader() {
      const varListUpdate = this.newsType.map((item) => ({
        ...item,
        text: item.name,
        url: `/news/${item.name}`,
      }));
      return {
        ...NewsKv.pageHeader,
        url: '/news/全部',
        listArray: [
          {
            id: '',
            url: '/news/全部',
            text: '全部',
          },
          ...varListUpdate,
        ],
      };
    },
    computedFooter() {
      for (let i = 0; i < this.footerMainListArray.length; i += 1) {
        const arrItem = this.footerMainListArray[i];

        /** 學會訊息的子頁籤改為後台創建 */
        if (arrItem.kvTitle2 === '學會訊息') {
          arrItem.url = this.computedPageHeader.url;
          arrItem.listArray = this.computedPageHeader.listArray;
        }
      }

      /** Footer會員專區重新組建 */
      let AccountKvCopy = {};
      const copyListArray = [];
      AccountKv.pageHeader.listArray.forEach((item) => {
        /** Footer會員專區的子頁籤不需出現「活動紀錄」 */
        if (item.text === '活動紀錄') {
          return;
        }

        /** 會員專區重組(待刪除功能)：相關查詢其餘頁籤未開發，除了level-1會員外先隱藏 */
        // if (item.text === '相關查詢' && parseInt(this.$cookies.get('level'), 10) !== 1) {
        //   return;
        // }

        copyListArray.push(item);
      });
      AccountKvCopy = { ...AccountKv.pageHeader, listArray: copyListArray };

      /** 若無會員登入時，則隱藏會員專區 */
      if (!this.isAuthenticated) {
        // return this.footerMainListArray.filter((data) => data.kvTitle2 !== '會員專區');
        return this.footerMainListArray;
      }
      return { ...this.footerMainListArray, AccountKvCopy };
    },
  },
  methods: {
    ...mapMutations([
      'updateTabName',
      'updateNewsType',
    ]),
    tabClicked(tabName) {
      this.updateTabName(tabName);
      this.updateNewsType(tabName);
    },
  },
};
</script>
