<template>
  <div>
    <div class="w1100 page_header_space">
      <h3 class="txt-bold">聯絡我們</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <section id="contact">
      <div class="bg">
        <img src="@/statics/img/about/bg.webp" alt="">
      </div>
      <div class="container w1100">
        <div class="col50">
          <div class="name">
            <p class="txt-light_green">Association of Home Care</p>
            <h2 class="txt-dark_green">社團法人台灣居家醫療醫學會</h2>
          </div>
          <div class="content">
            <h4><img src="@/statics/img/about/icon_call.svg" alt="">聯絡電話 0988-206590</h4>
            <!-- <h4><img src="@/statics/img/about/icon_fax.svg" alt="">傳真號碼 04-24362334</h4> -->
            <h4>
              <img src="@/statics/img/about/icon_mail.svg" alt="">電子信箱 hmca.tw@gmail.com
            </h4>
            <h4><img src="@/statics/img/about/icon_location.svg" alt="">宜蘭縣羅東鎮公正路167-3號</h4>
          </div>
        </div>
        <div class="col50">
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.397592038333!2d121.7662592154751!3d24.6788555841398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3467e67f026ded27%3A0x51dba0f784249ccf!2zMjY15a6c6Jit57ij576F5p2x6Y6u5YWs5q2j6LevMTY3LTPomZ8!5e0!3m2!1szh-TW!2stw!4v1675219525348!5m2!1szh-TW!2stw"
            width="1200"
            height="684"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0">
          </iframe>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutContact',
  data() {
    return {

    };
  },
};
</script>
