<template>
  <div id="chair_man" :style="{backgroundImage: `url(${require('@/statics/img/about/bg.webp')})`}">
    <div class="title w1200 page_header_space">
      <h3 class="txt-bold">理事長的話</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <img class="bg_heart" src="@/statics/img/about/bg_heart.webp" alt="">

    <div class="content w1200">
      <div class="col50">
        <h2 class="title">讓真正有需要的人能及時得到醫療處置</h2>
        <p class="desc">
          居家醫療是一種永遠需要的社區醫療模式，提供失能者的可近性醫療模式。居家醫療團隊面對的都是高齡失能個案，除了提
          供專業的醫療技能外，仍要面對生命最終的照顧以及家庭照顧者的社會問題；在獨立運作的過程，需要借助其他便利性科技
          的輔助，以及跨專業的合作，才能讓以人為本的價值醫療臻於完善。居家醫療醫學會面對這樣的挑戰，結合各個不同領域的
          專業醫師致力於本業的醫療技能提升，提供跨專業整合平台，一起共構社區醫療健康照護網絡。
        </p>
        <div class="list">
          <h4 class="list-title txt-medium">居家醫療醫學會的目的</h4>
          <div class="list-wrapper">
            <div class="circle_number">1</div>
            <p>精進整合性醫療識能，提供專業照護品質。</p><br>
          </div>
          <div class="list-wrapper">
            <div class="circle_number">2</div>
            <p>以個案為中心結合醫療長照服務。</p><br>
          </div>
          <div class="list-wrapper">
            <div class="circle_number">3</div>
            <p>運用IT廣化醫療服務層面。</p><br>
          </div>
          <div class="list-wrapper">
            <div class="circle_number">4</div>
            <p>運用遠距照護提供在地療養的機會。</p><br>
          </div>
          <div class="list-wrapper">
            <div class="circle_number">5</div>
            <p>推動安寧緩和醫療，尊重生命自主及善終全。</p>
          </div>
        </div>
      </div>

      <div class="col50">
        <div class="img_wrap">
          <img class="hands" src="@/statics/img/about/img_1.webp" alt="">
          <img class="sign" src="@/statics/img/about/img_2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutChairman',
  data() {
    return {

    };
  },
};
</script>
